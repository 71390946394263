import { Collapse, Popover, Typography, } from 'antd';
import TimeSeriesChart from "../../_components/TimeSeriesChart";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { chartName } from "../../_constants";

const { Panel } = Collapse;

const HealthView = ({
    chargeboxId,
    evseUids
}) => {

    const stopChildPropagation = (e) => {
        e.stopPropagation();
    }

    const sessionSuccessMetricsHeaderInfoContent = (
        <body>
            <h3><b>P</b>lug<b>INS</b>uccess</h3>
            <ul><li>Calulated by (# of OCPPSid not null or -1)/(# of SMID not null or -1)
                in SessionInstrumentation DataTransfer.req OCPP messages received from charger</li></ul>
        </body>
    )

    const energyDistributionMetricsHeaderInfoContent = (
        <body>
            <h3>Total Energy</h3>
            <ul><li>Total kWh distributed by charger, calculated from meter readings in StartTransaction.req,
                MeterValues.req, and StopTransaction.req OCPP messages from charger</li></ul>
            <h3>Energy Utilization</h3>
            <ul><li>'Utilized' kWh distributed by charger, meaning distributed during non-micro,
                non-freevend <b>billed</b> charging sessions</li></ul>
        </body>
    )

    const networkConnectivityMetricsHeaderInfoContent = (
        <body>
            <h3>Websocket Uptime</h3>
            <ul><li>Websocket connectivity</li></ul>
            <h3>Heartbeats Received</h3>
            <ul><li>Heartbeat.req OCPP messages received from charger</li></ul>
        </body>
    )

    const operationalStatusMetricsHeaderInfoContent = (
        <body>
            <h3>Operational Status</h3>
            <ul><li>Operational status changes of the dispenser over a time period</li></ul>
        </body>
    )

    return (
        <>
            <div className="deviceHealthContainer">
                <div className="healthGraphsContainer">
                    <Collapse ghost>
                        <Panel header={
                            <div className="sessionSuccessMetricsHeader">
                                <Typography.Text strong>
                                    Session Success Metrics
                                </Typography.Text>
                                <Popover content={sessionSuccessMetricsHeaderInfoContent} title={() => <h2>Session Success Metrics:</h2>} trigger="click" onClick={stopChildPropagation}>
                                    <QuestionCircleOutlined style={{ marginLeft: "1em" }} />
                                </Popover>
                            </div>
                        } key="1">
                            <TimeSeriesChart
                                percent={true}
                                chargeboxId={chargeboxId}
                                chartDataName={chartName.SESSION_SUCCESS_METRICS}
                            />
                        </Panel>
                        <Panel header={
                            <div className="energyDistributionMetricsHeader">
                                <Typography.Text strong>
                                    Energy Distribution Metrics
                                </Typography.Text>
                                <Popover content={energyDistributionMetricsHeaderInfoContent} title={() => <h2>Energy Distribution Metrics:</h2>} trigger="click" onClick={stopChildPropagation}>
                                    <QuestionCircleOutlined style={{ marginLeft: "1em" }} />
                                </Popover>
                            </div>
                        } key="2">
                            <TimeSeriesChart
                                percent={false}
                                chargeboxId={chargeboxId}
                                chartDataName={chartName.ENERGY_DISTRIBUTION_METRICS}
                            />
                        </Panel>
                        <Panel header={
                            <div className="networkConnectivityMetricsHeader">
                                <Typography.Text strong>
                                    Network Connectivity Metrics
                                </Typography.Text>
                                <Popover content={networkConnectivityMetricsHeaderInfoContent} title={() => <h2>Network Connectivity Metrics:</h2>} trigger="click" onClick={stopChildPropagation}>
                                    <QuestionCircleOutlined style={{ marginLeft: "1em" }} />
                                </Popover>
                            </div>
                        } key="3">
                            <TimeSeriesChart
                                percent={true}
                                chargeboxId={chargeboxId}
                                chartDataName={chartName.NETWORK_CONNECTIVITY_METRICS_WS}
                            />
                            <TimeSeriesChart
                                percent={false}
                                chargeboxId={chargeboxId}
                                chartDataName={chartName.NETWORK_CONNECTIVITY_METRICS_HEARTBEAT}
                            />
                        </Panel>
                        <Panel header={
                            <div className="operationalStatusMetricsHeader">
                                <Typography.Text strong>
                                    Operational Status Metrics
                                </Typography.Text>
                                <Popover content={operationalStatusMetricsHeaderInfoContent} title={() => <h2>Operational Status Metrics:</h2>} trigger="click" onClick={stopChildPropagation}>
                                    <QuestionCircleOutlined style={{ marginLeft: "1em" }} />
                                </Popover>
                            </div>
                        } key="4">
                            {
                                evseUids?.map(uid => {
                                    return <TimeSeriesChart
                                        percent={false}
                                        chargeboxId={uid} // evseUid is used for operational status metrics
                                        chartDataName={chartName.OPERATIONAL_STATUS_METRICS}
                                        useEvseUid={evseUids.length > 1}
                                    />
                                })
                            }
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </>
    );
};

export default HealthView;