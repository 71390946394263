export const timeGrainOptions = [
    {
      name: 'HOURLY',
      label: 'Hourly',
    },
    {
      name: 'DAILY',
      label: 'Daily',
    },
    {
      name: 'WEEKLY',
      label: 'Weekly',
    },
    {
      name: 'MONTHLY',
      label: 'Monthly',
    }
  ];

export const chartName = Object.freeze({
    SESSION_SUCCESS_METRICS: 'SESSION_SUCCESS_METRICS',
    NETWORK_CONNECTIVITY_METRICS_HEARTBEAT: 'NETWORK_CONNECTIVITY_METRICS_HEARTBEAT',
    NETWORK_CONNECTIVITY_METRICS_WS: 'NETWORK_CONNECTIVITY_METRICS_WS',
    ENERGY_DISTRIBUTION_METRICS: 'ENERGY_DISTRIBUTION_METRICS',
    OPERATIONAL_STATUS_METRICS:'OPERATIONAL_STATUS_METRICS'
});